html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
}

#wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  overflow: hidden;
  font-family: verdana;

  #container {
    // color: white;
    border: 1px solid red;

  }

  .svgLue {
    position: absolute;
    visibility: hidden;
    overflow: visible;
  }

  .svgHair {
    position: absolute;
    visibility: hidden;
    overflow: visible;

    .hairball {
      fill: #EEE;
    }
  }

  .svgFace {
    position: absolute;
    visibility: hidden;
    overflow: visible;

    .face {
      fill: #F9DCB2;
    }
  }


  .svgEyes {
    position: absolute;
    visibility: hidden;
    overflow: visible;
  }

  #sliderContainer {
    position: absolute;
    bottom: 7%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 80%;

    #sliderActive {
      position: relative;
      border: 0;
      top: -3px;
      height: 3px;
      background-color: #222;
    }

    #slider{
      border: 0;
      height: 3px;
      background-color: #444;

      *:focus {
          outline: 0;
      }

      .ui-corner-all {
        cursor: pointer;
        cursor: hand;
        border-radius: 20px;
      }

      .ui-slider-handle {
        transform: translate(-50%, 0);
        top: -1.5rem;
        width: 3rem;
        height: 3rem;
      }

      .ui-widget-content .ui-state-hover {
        border: 0;
      }
    }
  }
}


// < 950
@media only screen
  and (max-width: 970px) {

  #wrapper {

  }
}

// Tablet
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 1) {

  #wrapper {

  }
}

// Mobil
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {

  #wrapper {

  }
}
